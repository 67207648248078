import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Plugins

import Notifications from 'vue-notification'
import VueLazyload from 'vue-lazyload'
import Axios from 'axios'
import { Directus } from '@directus/sdk';
import VueMeta from 'vue-meta'

// Style

import './vue-tailwind'
import './index.css'

// Register Plugins
Vue.use(Notifications)
Vue.use(VueMeta)
Vue.use(VueLazyload)

// Lazy Load
const loadimage = require('./assets/img/transparent.png')
const errorimage = require('./assets/img/transparent.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

// Authentication

Vue.prototype.$http = Axios
const user_token = localStorage.getItem('user_token')
if (user_token) {
  Axios.defaults.headers.common['Authorization'] = `Bearer ${user_token}`
}


Vue.prototype.$directus = new Directus(process.env.VUE_APP_BACKEND_URL);

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
