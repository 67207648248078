<template>
  <div>
    <t-modal :value="showModal" @closed="$emit('closeModal')" variant="wide">
      <template v-slot:header>
        
        <h2 class="text-2xl">
          {{docData.titel}}
        </h2>
      </template>
      <img
        v-if="docData.icon"
        :src="`${files}/${docData.icon}`"
        :srcset="`${files}/${docData.icon}`"
        :alt="docData.titel"
        class="mb-4 max-h-96 w-full h-auto object-cover"
      />
      <div class="prose max-w-3xl" v-if="docData" v-html="docData.inhalt"></div>
      <!-- 
      <template v-slot:footer >
        <div class="flex flex-wrap">
          <t-button variant="secondary" class="ml-2"
            >Als PDF herunterladen</t-button
          >
        </div>
      </template> -->
    </t-modal>
  </div>
</template>
<script>
// Icons
import { PrinterIcon, FileIcon } from "vue-feather-icons";

export default {
  props: {
    DocID: {
      type: Number,
      default: null,
    },
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      docData: {
        titel: "",
        inhalt: "",
        slug: "",
      },
      files: `${process.env.VUE_APP_BACKEND_URL}/assets`,
    };
  },
  mounted() {
    this.getDocData();
    this.$router.push({ query: { doc: this.DocID } });
  },
  components: {
    PrinterIcon,
    FileIcon,
  },
  methods: {
    async getDocData() {
      const doc = await this.$directus
        .items("Docs")
        .readMany({ filter: { id: this.DocID } });
      this.setDocData(doc);
    },
    setDocData(doc) {
      this.docData = doc.data[0];
    },
  },
};
</script>
