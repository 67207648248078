<template>
  <div>
    <Header :checked="false" />

    <div class="app-list loader flex items-center">
      <div class="spinner mx-auto block">
        <div class="cube1"></div>
        <div class="cube2"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Layout/Header'

export default {
  components: {
    Header,
  },
  created() {
    try {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/auth/microsoft/callback?access_token=${this.$route.query.access_token}`
        )
        .then((res) => {
          this.$store.dispatch('login', {
            jwt: res.data.jwt,
            user: res.data.user,
          })
          this.$notify({
            group: 'global',
            type: 'success',
            title: 'Erfolgreich angemeldet!',
          })
        })
    } catch (e) {
      this.$notify({
        group: 'global',
        type: 'danger',
        title: 'Bei der Anmeldung ist ein Fehler aufgetreten.',
      })
      localStorage.removeItem('token')
      this.$store.commit('auth_error', e)
    }
  },
}
</script>
