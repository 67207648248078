<template>
  <div id="app">
    <Greeting />
    <notifications group="global" position="bottom right" class="global" />

    <router-view />
    <Footer />
  </div>
</template>
<script>

// Style
import '@/assets/scss/global.scss'

// Components
import Greeting from '@/components/Layout/Greeting'
import Footer from '@/components/Layout/Footer'
import BugReport from '@/components/Beta/BugReport'

export default {
  data() {
    return {
      checked: false,
    }
  },
  watch: {
    $route: function() {
      if (this.$route.path === '/lp') {
        this.checked = true
      } else {
        this.checked = false
      }
    },
  },
  components: {
    Greeting,
    Footer,
    BugReport,
  },
  metaInfo: {
    title: 'Schule Liestal Dashboard',
  },
  methods: {
    async getUser() {
      this.$http
        .get(`${process.env.VUE_APP_BACKEND_URL}/users/me`)
        .then((response) => {
          this.$store.commit('set_user', response.data)
          this.$store.commit('auth_refreshed')
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.getUser()
    }
  },
}
</script>
